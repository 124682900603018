<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="majors"
          :search="search"
          title="Jurusan"
          subtitle="Halaman untuk menambahkan jurusan"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else>
    </SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"

      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Jurusan': 'Edit Jurusan'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="major.name"
                label="Jurusan"
                outlined
                dense
              >
              </v-text-field>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Jurusan"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog.vue'
import DataTablePagination from '../components/DataTablePagination.vue'
import MainCard from '../components/MainCard.vue'
import ModalDialog from '../components/ModalDialog.vue'
import SkeletonLoaderTable from '../components/SkeletonLoaderTable.vue'

export default {
  name: 'Major',
  components: {
    DataTablePagination,
    MainCard,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
  },
  data() {
    return {
      icons: {},
      school: [],
      school_uuid: null,
      page: 1,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingTable: true,
      totalItems: 10,
      totalPages: 0,
      search: '',
      dialog: '',
      service: 'major',
      majors: [],
      major: {
        name: '',
      },
      modalDialog: false,
      confirmDialog: false,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Jurusan', value: 'name' },

        { text: 'Aksi', value: 'actions' },
      ],
      filterQuery: {
        name: '',
      },
      formValid: false,
    }
  },
  watch: {
    major: {
      handler() {
        const valid = []
        const requiredField = ['name']
        Object.entries(this.major).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.getMajor(this.filterQuery)
      },
    },
  },
  mounted() {
    this.getMajor()
  },
  methods: {
    resetForm() {
      this.major.name = ''
    },
    showFormAdd() {
      this.resetForm()
      this.dialog = 'add'
      this.modalDialog = true
    },
    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.major.uuid = uuid
      await this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.major = data.data
      })
      this.modalDialog = true
    },

    async getMajor(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, { ...params, page: this.page }).then(({ data }) => {
        this.majors = data.data.map((major, index) => ({
          ...major,
          index: index + 1,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
      this.isLoadingTable = false
    },
    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(this.service, this.major, this.major.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.major = {
            name: '',
          }
        },
      )
      await this.getMajor(this.filterQuery)
      await this.resetForm()
    },
    async add() {
      this.isLoadingButton = true

      if (this.major.name === '') {
        this.showSnackbar({
          text: 'please fill the input !!',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      await this.$services.ApiServices.add(this.service, this.major).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          console.errror(err)
          this.isLoadingButton = false
          this.major = {
            name: '',
          }
        },
      )
      await this.getMajor(this.filterQuery)
      await this.resetForm()
    },
    confirmDestroy(uuid) {
      this.major = {
        name: '',
      }
      this.confirmDialog = true
      this.major.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.major.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
        },
        err => {
          this.isLoadingButton = false
          this.major = {
            name: '',
          }
        },
      )
      await this.getMajor()
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    searchHandler(search) {
      this.filterQuery.name = search
      this.page = 1
      this.getMajor(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
